.FunnelBar {
  transform: translateY(-5px);
  width: 100%;
  .totalPercentageBar {
    .recharts-label {
      transform: translateY(-4%);
    }
  }
  .recharts-cartesian-grid-horizontal {
    display: none;
  }
  .recharts-cartesian-grid-vertical {
    transform: translateX(-151px);
    line:last-child {
      transform: translateX(150px);
    }
  }
}
