@mixin box {
  // max-height: 250px;
  text-align: center;
  background: var(--white);
  // border: 2px solid var(--white);
  box-shadow: 5px 5px 10px rgba(var(--gray_9), 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 15px;
  height: 100%;
  border: 2px solid var(--white);
  background: var(--white);
  backdrop-filter: blur(20px);
}

.AnalyticsContainer {
  width: 100%;
  // padding: 20px;
  // background: var(--gray_3);
  border-radius: 10px;

  .PieChartComponent {
    width: 100%;
    max-width: 380px;
    margin-top: var(--sp_lv_4);

    .chart-content {
      width: 50%;
      margin-right: var(--sp_lv_4);
    }

    .LegendsComponent {
      width: 50%;
      height: fit-content !important;
    }
  }

  .analytics-header {
    display: flex;
    min-height: 50px;
    justify-content: space-between;
    position: relative;

    .analytics-filters {
      // position: absolute;
      @include box;
      transition: 0.2s;
      padding: 0 10px;
      position: absolute;
      right: 0;
      z-index: 20;
      overflow: hidden;
      height: 34px;
      // width: 350px;
    }

    .is-filters-on {
      height: fit-content;
      overflow-y: scroll;
      box-shadow: 5px 5px 40px 10px rgba(var(--gray_9), 0.3);
    }

    .filters-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;

      .filter-btn {
        font-weight: 700;
        color: var(--light_gray);
        border-left: 1px solid var(--border);
        padding-left: 10px;
        font-size: 16px;

        cursor: pointer;

        &.has-filter {
          color: var(--secondary_blue) !important;
        }
      }
    }

    .date-picker {
      .ant-picker {
        background: none !important;
        border: none !important;
        box-shadow: none !important;

        .ant-picker-suffix {
          display: none;
        }

        input {
          text-align: center;
          color: var(--secondary_blue);
          font-weight: 500;
        }
      }
    }
  }

  .analytics-container {
    padding-top: var(--sp_lv_6);
    .error-text {
      color: var(--gray_9);
    }

    .analytics-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      width: 100%;
      min-width: 800px;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      margin-bottom: 10px;

      .box {
        @include box;
        min-height: 250px;
        min-width: 100px;

        &.box1 {
          grid-row-start: 1;
          grid-row-end: 5;
          justify-content: flex-start;
          overflow: scroll;

          &.isExpanded {
            grid-row-end: 4;
          }

          // max-height: 100%;
        }

        &.pie,
        &.bar {
          grid-column: span 2;
          height: 100%;
        }

        &.mixed {
          height: 400px;
        }

        &.span2 {
          grid-column: span 2;
        }
        &.span3 {
          grid-column: span 3;
        }

        &.span4 {
          grid-column: span 4;
        }
        &.span5 {
          grid-column: span 5;
        }
      }
    }
  }

  .ant-skeleton {
    height: 100%;
    // min-width: 90%;
    // position: absolute;
  }
}

.TableChartComponent {
  width: 100%;
  height: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  .ant-table {
    max-height: 450px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .ant-table .ant-table-content {
    table {
      th {
        padding: 2px;
        text-align: center;
        color: var(--white);
        background: var(--secondary_blue);
      }
      tbody {
        td.ant-table-cell {
          padding: 4px 8px;
        }
      }
    }
  }

  .ant-table-content {
    -webkit-box-shadow: var(--bot_2);
    box-shadow: var(--bot_2);
  }

  @for $i from 0 through 100 {
    .row-#{$i} {
      .ant-table-cell:nth-child(3),
      .ant-table-cell:nth-child(2) {
        text-align: right;
        background-image: linear-gradient(to right, var(--ocean_blue), var(--ocean_blue));
        background-repeat: no-repeat;
        background-size: #{$i + 1 + "%"};
        border-left: 1px solid var(--gray_3);
      }
    }
  }
}
