.DynamicContentComponent {
  position: relative;
  .dropdown-wrapper {
    display: flex;
    gap: var(--sp_lv_2);
    position: absolute;
    top: -30px;
    right: 0;
  }
  .__action-btn {
    cursor: pointer;
    color: var(--brand_primary);
  }
}
